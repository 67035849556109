import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { isEmpty, sortBy, reverse, startCase } from 'lodash';
import CheckCircleF from '@decisiv/iconix/lib/components/CheckCircleF';
import CheckCircle from '@decisiv/iconix/lib/components/CheckCircle';
import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import ExclamationTriangleF from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import Hourglass from '@decisiv/iconix/lib/components/Hourglass';
import {
  Button,
  Flex,
  H4,
  P,
  Pagination,
  Popover,
  Table,
  Tag,
} from '@decisiv/ui-components';
import moment from 'moment/moment';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';
import Redo from '@decisiv/iconix/lib/components/Redo';
import Copy from '@decisiv/iconix/lib/components/Copy';

export default function ExecutionsListTable({
  currentPageExecutions,
  currentStartIndex,
  currentEndIndex,
  itemsPerPage,
  executions,
  totalPages,
  onPageChange,
  currentPage,
  setSelectedExecution,
  isRetryButtonVisible,
  isRetryButtonDisabled,
  handleRetry,
  retryExecutionIdRef,
  setSortedExecutions,
  getExecutions,
  setExecutionModalDetailsVisibility,
  tableColumns,
}) {
  const onSort = useCallback(
    (columnName) => (sortingType) => {
      switch (sortingType) {
        case 'ascending':
          setSortedExecutions(sortBy(executions, [columnName]));
          return;
        case 'descending':
          setSortedExecutions(reverse(sortBy(executions, [columnName])));
          return;
        case 'unsorted':
        default:
          setSortedExecutions(executions);
      }
    },
    [executions, setSortedExecutions],
  );

  function truncateText(str, maxLength = 100) {
    if (isEmpty(str)) {
      return '';
    }

    if (str.length > maxLength) {
      return `${str.substring(0, maxLength)}...`;
    }
    return str;
  }

  const handleShowModal = (data) => {
    setSelectedExecution(data.rowData.attributes);
    setExecutionModalDetailsVisibility(true);
  };

  const baseColumns = [
    {
      DataCell: (data) => (
        <a
          target="_blank"
          href={`/integrations/${data?.rowData?.attributes?.integration_id}`}
          rel="noreferrer"
        >
          {data?.rowData?.attributes?.integration_name}
        </a>
      ),
      title: t`INTEGRATION`,
      name: 'integration_name',
    },
    {
      DataCell: (data) => (
        <Flex>
          <span>{data?.rowData?.attributes?.x_decisiv_trace_id}</span>
          <span>
            <Flex alignItems="center">
              <Popover
                placement="bottom-start"
                manageEvents="hover"
                target={({ ref, toggle }) => (
                  <Button
                    ref={ref}
                    action={toggle}
                    icon={Copy}
                    style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                    variant="ghost"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        data?.rowData?.attributes?.x_decisiv_trace_id,
                      )
                    }
                    size="small"
                  />
                )}
              >
                <Flex padding={1}>
                  <span>{t`Copy Id`}</span>
                </Flex>
              </Popover>
            </Flex>
          </span>
        </Flex>
      ),
      title: 'X-DECISIV-TRACE-ID-OMEGA',
      name: 'x_decisiv_trace_id',
    },
    {
      DataCell: (data) => (
        <a
          target="_blank"
          href={`/location_settings/${data?.rowData?.attributes?.configured_integration_id}`}
          rel="noreferrer"
        >
          {data?.rowData?.attributes?.location_name}
        </a>
      ),
      title: t`LOCATION`,
      sortBy: onSort('attributes.location_name'),
      name: 'location_name',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.city && data.rowData.attributes.state ? (
              <>
                <span>
                  {data.rowData.attributes.city},{' '}
                  {data.rowData.attributes.state}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      sortBy: onSort('attributes.city'),
      title: 'CITY/STATE',
      name: 'city',
    },
    {
      DataCell: (data) => {
        let text;
        let color;
        let icon;
        let errorTitle;
        let errorDesc;

        switch (data.rowData.attributes.status) {
          case 'SUCCEEDED':
            color = 'success';
            text = 'Succeeded';
            icon = CheckCircleF;
            break;
          case 'ExecutionDoesNotExist':
            color = 'danger';
            text = 'Execution Does Not Exist';
            icon = ExclamationTriangleF;
            break;
          case 'RUNNING':
            color = 'information';
            text = 'Processing';
            icon = Hourglass;
            break;
          case 'NOT_FOUND':
            color = 'success';
            text = 'Succeeded - No Results';

            // eslint-disable-next-line no-case-declarations
            const errorArray = JSON.parse(
              data.rowData.attributes.output,
            )?.errors;

            if (errorArray && errorArray.length > 1) {
              errorDesc = truncateText(errorArray[0]?.detail);

              errorTitle = errorArray[0]?.title;
            } else {
              errorTitle = null;
              errorDesc = null;
            }

            icon = CheckCircle;
            break;
          case 'FAILED':
          default:
            color = 'danger';
            text = 'Failed';
            errorTitle = JSON.parse(data.rowData.attributes.output)?.errors[0]
              ?.title;
            errorDesc = truncateText(
              JSON.parse(data.rowData.attributes.output)?.errors[0]?.detail,
            );
            icon = ExclamationTriangle;
        }

        let outTag = null;
        if (
          data.rowData.attributes.status &&
          data.rowData.attributes.status !== 'RUNNING'
        ) {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom"
                  manageEvents="hover"
                  target={({ ref, toggle }) => (
                    <Tag
                      ref={ref}
                      color={color}
                      icon={icon}
                      variant="outline"
                      action={toggle}
                      style={{ marginLeft: '15px' }}
                    />
                  )}
                >
                  <Flex padding={1} flexDirection="column">
                    {errorTitle && errorDesc ? (
                      <>
                        <H4 weight="">{text}</H4>
                        <P marginTop={0.5} shade={0}>
                          <span style={{ fontWeight: '600' }}>title: </span>
                          {errorTitle}
                        </P>
                        <P marginTop={0.5} shade={0} truncate>
                          <span style={{ fontWeight: '600' }}>detail: </span>
                          {errorDesc}
                        </P>
                      </>
                    ) : (
                      <>
                        <span>{text}</span>
                      </>
                    )}
                  </Flex>
                </Popover>
                {isRetryButtonVisible(data.rowData.attributes) && (
                  <Popover
                    placement="bottom"
                    manageEvents="hover"
                    target={({ ref }) => (
                      <Button
                        icon={Redo}
                        ref={ref}
                        disabled={isRetryButtonDisabled(
                          executions,
                          data.rowData.attributes.x_decisiv_trace_id,
                        )}
                        loading={
                          retryExecutionIdRef.current ===
                          data.rowData.attributes.id
                        }
                        variant="ghost"
                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                        onClick={() =>
                          handleRetry(
                            data.rowData.attributes.id,
                            data.rowData.attributes.configured_integration_id,
                            data.rowData.attributes.invocation_payload,
                            getExecutions,
                          )
                        }
                      />
                    )}
                  >
                    <Flex padding={1}>
                      <span>{t`Retry`}</span>
                    </Flex>
                  </Popover>
                )}
              </Flex>
            </>
          );
        } else if (data.rowData.attributes.status === 'RUNNING') {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref }) => (
                    <Button
                      icon={Hourglass}
                      ref={ref}
                      variant="ghost"
                      style={{ cursor: 'pointer', marginLeft: '20px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        }

        return outTag;
      },
      title: t`STATUS`,
      sortBy: onSort('attributes.status'),
      name: 'http_status',
    },
    {
      DataCell: (data) => (
        <>
          <span>
            {startCase(
              data.rowData.attributes.source.split('_').pop().toLowerCase(),
            )}
          </span>
        </>
      ),
      sortBy: onSort('attributes.source'),
      title: t`SOURCE`,
      name: 'source',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.trigger_type === 'http_api_request' ? (
              <>
                <span>Http</span>
              </>
            ) : (
              <>
                <span>Application Event</span>
              </>
            )}
          </>
        );
      },
      title: t`Trigger`,
      sortBy: onSort('attributes.trigger_type'),
      name: 'trigger_type',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.start_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    moment(data.rowData.attributes.start_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      title: t`STARTED`,
      sortBy: onSort('attributes.start_date'),
      name: 'started',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.stop_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    data.rowData.attributes.stop_date &&
                    moment(data.rowData.attributes.stop_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      title: t`END TIME`,
      sortBy: onSort('attributes.stop_date'),
      name: 'end_time',
    },
    {
      DataCell: (data) => {
        return (
          <>
            <Button
              icon={Newspaper}
              variant="ghost"
              onClick={() => handleShowModal(data)}
              id={`showLogModalBtn-${data.rowData.id}`}
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      name: 'actions',
    },
  ];

  const columnsBuilder = (columns) => {
    // return baseColumns.filter(item => columns.includes(item.name));
    return columns
      .map((key) => baseColumns.find((item) => item.name === key))
      .filter((item) => item);
  };

  return (
    <Table
      columns={columnsBuilder(tableColumns)}
      data={currentPageExecutions}
      kind="secondary"
      getRowKey={({ id }) => id}
      footer={() => (
        <Flex flex={1} alignItems="center" justifyContent="space-between">
          <Flex>
            <P>
              {currentStartIndex + 1} -{' '}
              {itemsPerPage > executions.length
                ? executions.length
                : currentEndIndex}{' '}
              of {executions.length} Items
            </P>
          </Flex>
          <Pagination
            totalPages={totalPages}
            onPageChange={onPageChange}
            activePage={currentPage}
          />
        </Flex>
      )}
    />
  );
}

ExecutionsListTable.propTypes = {
  currentPageExecutions: PropTypes.number.isRequired,
  currentStartIndex: PropTypes.number.isRequired,
  currentEndIndex: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  executions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setSelectedExecution: PropTypes.func.isRequired,
  isRetryButtonVisible: PropTypes.bool.isRequired,
  isRetryButtonDisabled: PropTypes.bool.isRequired,
  handleRetry: PropTypes.func.isRequired,
  retryExecutionIdRef: PropTypes.func.isRequired,
  setSortedExecutions: PropTypes.func.isRequired,
  getExecutions: PropTypes.func.isRequired,
  setExecutionModalDetailsVisibility: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};
