import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, H3, P } from '@decisiv/ui-components';
import BuildingIcon from '@decisiv/iconix/lib/components/Building';
import iconix from '@decisiv/iconix/lib/components';
import { t, Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

import noop from 'lodash/noop';
import AddLocationModal from './AddLocationModal';
import LocationsResult from './LocationsResult';
import {
  IntegrationDetailDefaultNoLocationsIconWrapper,
  IntegrationDetailDefaultNoLocationsTitle,
  IntegrationDetailDefaultNoLocationsWrapper,
  IntegrationDetailLocations,
} from './style';

export default function IntegrationDetails({
  locations,
  integration,
  setRefresh,
}) {
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterOem, setFilterOem] = useState(['all']);
  const [filterDealerType, setFilterDealerType] = useState(['all']);

  return (
    <IntegrationDetailLocations
      textAlign="center"
      name="integrationDetailLocations"
    >
      {isEmpty(locations) ? (
        <IntegrationDetailDefaultNoLocationsWrapper
          flexDirection="column"
          paddingTop={2}
        >
          <IntegrationDetailDefaultNoLocationsIconWrapper
            flexDirection="row"
            alignItems="center"
            alignSelf="center"
          >
            <BuildingIcon size="large" color="#fff" />
          </IntegrationDetailDefaultNoLocationsIconWrapper>
          <IntegrationDetailDefaultNoLocationsTitle
            justifyContent="center"
            paddingTop={1}
            flexDirection="column"
          >
            <H3
              color="charcoal"
              weight="medium"
              style={{ 'overflow-wrap': 'break-word' }}
            >
              <Trans>No Locations with</Trans>
              &nbsp;{integration.attributes.name}
              &nbsp;
              <Trans> Integration Found </Trans>
            </H3>
            <P color="alaskanHusky" weight="medium">
              <Trans>
                Start by adding locations, then you will be able to configure
                their settings and status.
              </Trans>
            </P>
          </IntegrationDetailDefaultNoLocationsTitle>
          <Flex alignSelf="center" padding={2}>
            <Button
              icon={iconix.PlusCircle}
              text={t`ADD LOCATION`}
              size="medium"
              onClick={() => setVisibleModal(true)}
            />
          </Flex>
        </IntegrationDetailDefaultNoLocationsWrapper>
      ) : (
        <LocationsResult
          loading={loading}
          setLoading={setLoading}
          filterSearch={filterSearch}
          setFilterSearch={setFilterSearch}
          locations={locations}
          filterOem={filterOem}
          setFilterOem={setFilterOem}
          filterDealerType={filterDealerType}
          setFilterDealerType={setFilterDealerType}
          setVisibleModal={setVisibleModal}
          integrationName={integration.attributes.name}
        />
      )}
      <AddLocationModal
        setVisibleModal={setVisibleModal}
        visibleModal={visibleModal}
        integration={integration}
        setRefresh={setRefresh}
        locations={locations}
      />
    </IntegrationDetailLocations>
  );
}

IntegrationDetails.defaultProps = {
  integration: {},
  setRefresh: noop,
};

IntegrationDetails.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      oem: PropTypes.string,
      type: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integration: PropTypes.objectOf(PropTypes.any),
  setRefresh: PropTypes.func,
};
