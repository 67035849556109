import { useRef } from 'react';
import ConfiguredIntegrationService from '../api/configured_integrations';

const useRetryExecutionFunctions = () => {
  const retryExecutionIdRef = useRef(null);

  const isRetryButtonVisible = (execution) =>
    execution.trigger_type === 'application_event' &&
    execution.status === 'FAILED';

  const isRetryButtonDisabled = (executions, xDecisivTraceId) =>
    executions.some(
      (execution) =>
        execution.attributes.parent_x_decisiv_trace_id === xDecisivTraceId,
    );

  const handleRetry = async (
    executionId,
    configuredIntegrationUuid,
    payload,
    refreshCallback,
  ) => {
    retryExecutionIdRef.current = executionId;

    await ConfiguredIntegrationService.invokeIntegration(
      configuredIntegrationUuid,
      payload,
      'MANUAL_RETRY',
    );

    retryExecutionIdRef.current = null;
    await refreshCallback();
  };

  return {
    isRetryButtonVisible,
    isRetryButtonDisabled,
    handleRetry,
    retryExecutionIdRef,
  };
};

export default useRetryExecutionFunctions;
