import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import OptionsList from '@decisiv/ui-components/lib/atoms/OptionsList';
import iconix from '@decisiv/iconix';
import { P } from '@decisiv/ui-components';
import { titlelizeCommand } from '../../utils/titlelizeCommand';
import { CommandLabel } from './style';
import './style.css';

export default function CommandList({ commands, filters, childResult }) {
  const [selectedIds, setSelectedIds] = useState('');
  const valueNotFoundIcon = {
    type: 'Badge',
    color: 'licoriceMousse',
    icon: iconix.Minus,
  };

  const result = useCallback(
    (selected) => {
      setSelectedIds(selected);
      childResult(selected);
    },
    [setSelectedIds, childResult],
  );

  const setLabel = (item) => {
    const key = commands.indexOf(item);

    return (
      <CommandLabel key={key}>
        <span className="spanLabel">
          <P weight="semibold">{titlelizeCommand(item.attributes.name)}</P>
          <span className="spanDetail">
            <P>Command</P>
          </span>
        </span>
      </CommandLabel>
    );
  };

  /*
  setIcon= search params to set icon in OptionList component for each command
    item = value in optionList ('FindCaseCommand')
    filters = array in commandSerch component with tags and filters
                [{icon:'Bar' text:'Case' color:'fizzyLime' tags:[..., FindCaseCommand, ...}, {}, ...]
    value = return array match with item
              [icon:'Bar' text:'Case' color:'fizzyLime' tags:[..., FindCaseCommand, ...]]
    filterTag = element in filters array
    output = return match value in filter.filterTag.tag with value in optionList ('FindCaseCommand')
    setIcon = return icon object match with value in list optionList to decoration option
                {type: 'Badge', color: value.color, icon: value.icon}
  */
  const setIcon = useCallback(
    (item) => {
      const value = filters
        .map((filterTag) => {
          const output = filterTag.text === item;
          if (output) return filterTag;
          return false;
        })
        .find((icon) => {
          return icon !== false;
        });
      if (!value) return valueNotFoundIcon;

      return {
        type: 'Badge',
        color: value.color,
        icon: value.icon,
      };
    },
    [filters],
  );

  const optionsListItems = useMemo(
    () =>
      commands.map((command, index) => ({
        id: command.attributes.name,
        key: command.attributes.name,
        disabled: false,
        width: '100%',
        decoration: setIcon(command.attributes.category),
        meta: {
          command,
          index,
        },
      })),
    [commands, setIcon],
  );

  return (
    <>
      <OptionsList
        maxWidth="100%"
        maxHeight="100%"
        items={optionsListItems}
        name="single-with-badge"
        selectedIds={selectedIds}
        onChange={result}
        selected={selectedIds}
        renderOptionLabel={({ meta: { command } }) => setLabel(command)}
      />
    </>
  );
}

CommandList.propTypes = {
  commands: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      attributes: PropTypes.arrayOf,
    }),
  ).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      text: PropTypes.string,
      color: PropTypes.string,
      tags: PropTypes.arrayOf,
    }),
  ).isRequired,
  childResult: PropTypes.func.isRequired,
};
