import React from 'react';
import { PropTypes } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Button, Flex, H4, Modal, P, Popover } from '@decisiv/ui-components';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';
import Redo from '@decisiv/iconix/lib/components/Redo';
import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';
import moment from 'moment';
import { startCase } from 'lodash';
import Copy from '@decisiv/iconix/lib/components/Copy';
import Link from '@decisiv/iconix/lib/components/Link';
import ContentAccordion from '../ContentAccordion';
import StatusTag from './StatusTag';
import useRetryExecutionFunctions from '../../hooks/useRetryExecutionFunctions';

export default function LogDetailsModal({
  log,
  visibility,
  handleVisibility,
  modalTitle,
  logs,
  onRefreshLogs,
}) {
  const {
    isRetryButtonVisible,
    isRetryButtonDisabled,
    handleRetry,
    retryExecutionIdRef,
  } = useRetryExecutionFunctions();

  const getEnvironment = (url) => {
    const regex = /integrate\.(qa|staging)\.decisivapps\.com/;
    const match = url.match(regex);

    if (match) {
      return match[1];
    }

    return 'production';
  };

  const formatLogUrl = (logInfo) => {
    const dateString = logInfo?.start_date;
    const date = new Date(dateString);
    const unixTimestamp = date.getTime();

    const arn = logInfo?.execution_arn;

    if (arn?.includes('http-api-request')) {
      return `https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/express-executions/details/${arn}?startDate=${unixTimestamp}`;
    }
    return `https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/v2/executions/details/${arn}`;
  };

  return (
    <Modal
      actions={[
        {
          text: t`Close`,
          onClick: () => handleVisibility(false),
          kind: 'secondary',
        },
      ]}
      intent="information"
      icon={Newspaper}
      title={modalTitle || t`Execution Details`}
      visible={visibility}
      onClose={() => handleVisibility(false)}
    >
      <Flex flexDirection="column">
        <Flex marginTop={1}>
          <H4 weight="extrabold">{log?.integration_name}</H4>
        </Flex>
        <Flex>
          <P weight="semibold">{log?.location_name}</P>
        </Flex>
        <Flex>
          <P color="charcoal" weight="semibold">
            <Trans>Environment:</Trans>&nbsp;
          </P>
          <P weight="regular" color="alaskanHusky">
            {getEnvironment(process.env.REACT_APP_BASE_URL)}
          </P>
        </Flex>
        <Flex
          marginTop={2}
          flexDirection="row"
          justifyContent="space-between"
          style={{ width: '100%', whiteSpace: 'nowrap' }}
        >
          <Flex flexDirection="column">
            <Flex flexDirection="row">
              <P color="charcoal" weight="semibold">
                <Trans>X-Decisiv-Trace-ID:</Trans>&nbsp;
              </P>
            </Flex>
            <Flex flexDirection="row">
              <P size="small" weight="regular" color="alaskanHusky">
                {log?.x_decisiv_trace_id}
              </P>
              <Button
                icon={Link}
                style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                variant="ghost"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${window.location.origin}/toolkit/log_inspector/${log?.x_decisiv_trace_id}`,
                  )
                }
                size="small"
              />
              <Button
                icon={Copy}
                style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                variant="ghost"
                onClick={() =>
                  navigator.clipboard.writeText(log?.x_decisiv_trace_id)
                }
                size="small"
              />
            </Flex>
            {log?.parent_x_decisiv_trace_id && (
              <>
                <Flex flexDirection="row">
                  <P color="charcoal" weight="semibold">
                    <Trans>Parent-X-Decisiv-Trace-ID:</Trans>&nbsp;
                  </P>
                </Flex>
                <Flex flexDirection="row">
                  <P size="small" weight="regular" color="alaskanHusky">
                    {log.parent_x_decisiv_trace_id}
                  </P>
                  <Button
                    icon={Link}
                    style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                    variant="ghost"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${window.location.origin}/toolkit/log_inspector/${log.parent_x_decisiv_trace_id}`,
                      )
                    }
                    size="small"
                  />
                  <Button
                    icon={Copy}
                    style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                    variant="ghost"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        log.parent_x_decisiv_trace_id,
                      )
                    }
                    size="small"
                  />
                </Flex>
              </>
            )}
          </Flex>
          <Flex flexDirection="column" marginLeft={2}>
            {log?.response_from_cache && (
              <>
                <Flex flexDirection="row">
                  <P color="charcoal" weight="semibold">
                    <Trans>Cache Source X-Decisiv-Trace-ID:</Trans>&nbsp;
                  </P>
                </Flex>
                <Flex flexDirection="row">
                  <P size="small" weight="regular" color="alaskanHusky">
                    {log?.x_decisiv_trace_id_cache_source}
                  </P>
                  <Button
                    icon={Link}
                    style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                    variant="ghost"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${window.location.origin}/toolkit/log_inspector/${log?.x_decisiv_trace_id_cache_source}`,
                      )
                    }
                    size="small"
                  />
                  <Button
                    icon={Copy}
                    style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                    variant="ghost"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        log?.x_decisiv_trace_id_cache_source,
                      )
                    }
                    size="small"
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex
          marginTop={2}
          flexDirection="row"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Flex flexDirection="column" marginLeft={1}>
            <Flex>
              <P weight="semibold">
                <Trans>STATUS</Trans>
              </P>
            </Flex>
            <Flex>
              <StatusTag status={log?.status} />
              {isRetryButtonVisible(log) && (
                <Popover
                  placement="bottom"
                  manageEvents="hover"
                  target={({ ref }) => (
                    <Button
                      icon={Redo}
                      ref={ref}
                      disabled={isRetryButtonDisabled(
                        logs,
                        log.x_decisiv_trace_id,
                      )}
                      loading={retryExecutionIdRef.current === log.id}
                      variant="ghost"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        marginTop: '-6px',
                      }}
                      onClick={() =>
                        handleRetry(
                          log.id,
                          log.configured_integration_id,
                          log.invocation_payload,
                          () => {
                            setTimeout(() => {
                              handleVisibility(false);
                              onRefreshLogs();
                            }, 3000);
                          },
                        )
                      }
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{t`Retry`}</span>
                  </Flex>
                </Popover>
              )}
            </Flex>
          </Flex>

          <Flex flexDirection="column" marginLeft={1}>
            <Flex>
              <P weight="semibold">
                <Trans>SOURCE</Trans>
              </P>
            </Flex>
            <Flex>
              <P weight="medium" color="alaskanHusky">
                {' '}
                {startCase(log?.source?.split('_')?.pop()?.toLowerCase())}
              </P>
            </Flex>
          </Flex>

          <Flex flexDirection="column" marginLeft={1}>
            <Flex>
              <P weight="semibold">
                <Trans>STARTED</Trans>
              </P>
            </Flex>
            <Flex>
              <P weight="medium" color="alaskanHusky">
                {' '}
                {moment(log?.start_date).format('MM/DD/YYYY hh:mm:ss a z')}{' '}
              </P>
            </Flex>
          </Flex>

          <Flex flexDirection="column">
            <Flex>
              <P weight="semibold">
                <Trans>END TIME</Trans>
              </P>
            </Flex>
            <Flex>
              <P weight="medium" color="alaskanHusky">
                {' '}
                {moment(log?.stop_date).format('MM/DD/YYYY hh:mm:ss a z')}{' '}
              </P>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          name="header-info"
          flexDirection="column"
          marginTop={2}
          style={{
            whiteSpace: 'nowrap',
            border: '1px solid #e3ebf1',
            padding: '0.625rem',
          }}
        >
          <P weight="semibold">
            <Trans>ADDITIONAL INFORMATION</Trans>
          </P>
          {log?.response_from_cache && (
            <Flex>
              <P color="charcoal" weight="semibold">
                <Trans>Cache Age:</Trans>&nbsp;
              </P>
              <P weight="regular" color="alaskanHusky">
                {log?.cache_age}
              </P>
            </Flex>
          )}
          <Flex>
            <P color="charcoal" weight="semibold">
              <Trans>Time Taken:</Trans>&nbsp;
            </P>
            <P weight="regular" color="alaskanHusky">
              {log?.time_taken}
            </P>
          </Flex>
          <Flex>
            <P color="charcoal" weight="semibold">
              <Trans>Invocation Type:</Trans>&nbsp;
            </P>
            <P weight="regular" color="alaskanHusky">
              {log?.trigger_type === 'application_event'
                ? 'Application Event'
                : 'HTTP Api Request'}
            </P>
          </Flex>
          <span>
            <P color="charcoal" weight="alaskanHusky">
              <Trans>Application Event:</Trans>&nbsp;
            </P>
            <P weight="regular" color="alaskanHusky">
              {log?.trigger_type === 'application_event'
                ? log?.action_or_application_event
                : '⁠—'}
            </P>
          </span>
          <span>
            <P color="charcoal" weight="alaskanHusky">
              <Trans>HTTP Api Request:</Trans>&nbsp;
            </P>
            <P weight="regular" color="alaskanHusky">
              {log?.trigger_type === 'http_api_request'
                ? log?.action_or_application_event
                : '⁠—'}
            </P>
          </span>
          <Flex>
            <P color="charcoal" weight="semibold">
              <Trans>Invocation Method:</Trans>&nbsp;
            </P>
            <P weight="regular" color="alaskanHusky">
              {log?.sync ? 'Synchronous' : 'Asynchronous'}
            </P>
          </Flex>
          <Flex flexDirection="column">
            <Flex>
              <P weight="semibold">
                <Trans>AWS Step Function</Trans>
              </P>
              <Flex
                marginLeft={0.5}
                onClick={() => window.open(formatLogUrl(log), '_blank')}
              >
                <a target="_blank" href={formatLogUrl(log)} rel="noreferrer">
                  {log?.execution_arn?.split(':')?.pop()}{' '}
                  <ExternalLink size="medium" />
                </a>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex marginTop={1} flexDirection="column">
          <Flex marginTop={1}>
            <ContentAccordion content={log} />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

LogDetailsModal.propTypes = {
  log: PropTypes.oneOfType([PropTypes.object]),
  logs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  visibility: PropTypes.bool.isRequired,
  handleVisibility: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  onRefreshLogs: PropTypes.func,
};

LogDetailsModal.defaultProps = {
  modalTitle: 'Execution Details',
  log: {},
  onRefreshLogs: () => {},
};
