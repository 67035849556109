import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { noop } from 'lodash';
import ExecutionDetailsModal from '../../components/ExecutionDetails/ExecutionDetailsModal';
import ExecutionsListTable from '../../components/ExecutionsListTable';
import useRetryExecutionFunctions from '../../hooks/useRetryExecutionFunctions';

export default function LogList({ logsList, logsHistory, onRefresh }) {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [totalLogsPage, setTotalLogsPage] = useState(0);
  const [currentPageLogs, setCurrentPageLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const [logModalDetailsVisibility, setLogModalDetailsVisibility] =
    useState(false);
  const [selectedLogDetail, setSelectedLogDetail] = useState();
  const {
    isRetryButtonVisible,
    isRetryButtonDisabled,
    handleRetry,
    retryExecutionIdRef,
  } = useRetryExecutionFunctions();

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);

    // Calculate the starting index and ending index for the specified page
    const startIndex = (newPageNumber - 1) * itemsPerPage;
    setCurrentStartIndex(startIndex);
    const endIndex = startIndex + itemsPerPage;
    setCurrentEndIndex(endIndex);
    // Use slice to get the portion of the array for the specified page
    const pageLogs = logs?.slice(startIndex, endIndex);
    setTotalLogsPage(Math.ceil(logs?.length / itemsPerPage));
    setCurrentPageLogs(pageLogs);
  };

  useEffect(() => {
    setItemsPerPage(25);
    pageChangeHandler(1);
    setLogs(logsList);
  }, [logs]);

  return (
    <>
      <ExecutionDetailsModal
        log={selectedLogDetail}
        visibility={logModalDetailsVisibility}
        handleVisibility={setLogModalDetailsVisibility}
        logs={logsHistory}
        onRefreshLogs={() => onRefresh(logs[0]?.attributes?.x_decisiv_trace_id)}
      />

      <ExecutionsListTable
        currentPageExecutions={currentPageLogs}
        currentStartIndex={currentStartIndex}
        currentEndIndex={currentEndIndex}
        itemsPerPage={itemsPerPage}
        executions={currentPageLogs}
        totalPages={totalLogsPage}
        onPageChange={pageChangeHandler}
        currentPage={currentPage}
        setSelectedExecution={setSelectedLogDetail}
        isRetryButtonVisible={isRetryButtonVisible}
        isRetryButtonDisabled={isRetryButtonDisabled}
        handleRetry={handleRetry}
        retryExecutionIdRef={retryExecutionIdRef}
        setSortedExecutions={noop}
        getExecutions={noop}
        setExecutionModalDetailsVisibility={setLogModalDetailsVisibility}
        tableColumns={[
          'integration_name',
          'location_name',
          'x_decisiv_trace_id',
          'http_status',
          'source',
          'started',
          'end_time',
          'actions',
        ]}
      />
    </>
  );
}

LogList.defaultProps = {
  logsList: [],
};

LogList.propTypes = {
  logsList: PropTypes.oneOfType([PropTypes.array]),
  logsHistory: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onRefresh: PropTypes.func.isRequired,
};
