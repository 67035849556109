import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Filter,
  Flex,
  Grid,
  H1,
  H4,
  Message,
  P,
  Tab,
  Tabs,
  Tag,
  TextArea,
  TextField,
  Toggle,
} from '@decisiv/ui-components';
import iconix from '@decisiv/iconix';
import Bars from '@decisiv/iconix/lib/components/Bars';
import { t, Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { FormGroup } from '../IntegrationDetailPage/StyledComponents';
import Paper from '../../components/Paper';
import {
  CommandSearchSection,
  CommandSection,
  DeveloperCodeView,
  DeveloperSection,
  DeveloperSectionHead,
  DisplayCode,
  FilterTextField,
  LineDivider,
  LineVerticalDivider,
  SectionRow,
  Stack,
} from './style';
import CommandList from './commandList';
import CommandsService from '../../api/commands';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import { titlelizeCommand } from '../../utils/titlelizeCommand';

export default function CommandSearch() {
  const filters = [
    {
      icon: iconix.Envelope,
      text: 'Email',
      color: 'carrotCake',
    },
    {
      icon: iconix.StickyNote,
      text: 'File',
      color: 'berryCrisp',
    },
    {
      icon: iconix.Briefcase,
      text: 'Case',
      color: 'fizzyLime',
    },
    {
      icon: iconix.Antenna,
      text: 'Endpoint',
      color: 'mamboMango',
    },
    {
      icon: Bars,
      text: 'Template',
      color: 'cottonCandy',
    },
    {
      icon: iconix.Jpro,
      text: 'Telematics',
      color: 'roseGummy',
    },
    {
      icon: iconix.Download,
      text: 'Manufacturer Info',
      color: 'koolaidCake',
    },
    {
      icon: iconix.BarChartScreen,
      text: 'Business System',
      color: 'warning',
    },
  ];
  const AMOUNT_OF_FILTERS = filters.length;
  const [commands, setCommands] = useState([]);
  const [commandsFilter, setCommandsFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCommand, setSelectedCommand] = useState('');
  const [filtersTagList, setFiltersTagList] = useState(filters);
  const [globalAttributes, setGlobalAttributes] = useState([]);
  const [locationAttributes, setLocationAttributes] = useState([]);
  const [tag, setTag] = useState('');
  const [selectedCommandName, setSelectedCommandName] = useState('');
  const [selectedCommandDescription, setSelectedCommandDescription] =
    useState('');

  const [viewMode, updateViewMode] = useState('default');

  function triggerViewModeChange(e) {
    const toggledViewMode = e === 'disable' ? 'default' : 'dev';

    updateViewMode(toggledViewMode);
  }

  function resetDeveloperMode() {
    updateViewMode('default');
  }

  useEffect(() => {
    setLoading(true);
    const fetchSResponse = async () => {
      const response = await CommandsService.fetchCommands();

      if (response.data) {
        setCommands(response.data);
        setCommandsFilter(response.data);
      } else setCommands([]);
      setLoading(false);
    };

    fetchSResponse();
  }, []);

  function childResult(commandItem) {
    const currentCommand = commands.find((command) => {
      return command.attributes.name.includes(commandItem);
    });

    const currentTag = filters.find(
      (filter) => filter.text === currentCommand.attributes.category,
    );

    setTag(currentTag);
    setSelectedCommandName(commandItem);
    setSelectedCommand(currentCommand);
    setSelectedCommandDescription(currentCommand.attributes.description);

    setGlobalAttributes(
      Object.entries(currentCommand.attributes.input_schema.properties)
        .filter(([, value]) => value.source === 'global_settings_attributes')
        .reduce((accumulator, [key, value]) => {
          accumulator[key] = value;
          return accumulator;
        }, {}),
    );

    setLocationAttributes(
      Object.entries(currentCommand.attributes.input_schema.properties)
        .filter(([, value]) => value.source === 'location_settings_attributes')
        .reduce((accumulator, [key, value]) => {
          accumulator[key] = value;
          return accumulator;
        }, {}),
    );
  }

  const decideFormElement = (input) => {
    const { name: formId } = input[0];

    const inputProps = {
      name: formId,
      label: input[0],
      key: formId,
      disabled: true,
      helpMessage: input[1].description,
    };

    /* istanbul ignore next */
    if (input[1].html_tag === 'text_input') {
      return <TextField {...inputProps} />;
    }

    /* istanbul ignore next */
    if (input[1].html_tag === 'password_input') {
      return <TextField {...inputProps} />;
    }

    /* istanbul ignore next */
    if (input[1].html_tag === 'checkbox_input') {
      return <Checkbox {...inputProps} />;
    }

    /* istanbul ignore next */
    if (
      input[1].html_tag === 'object_input' ||
      input[1].html_tag === 'text_area'
    ) {
      return <TextArea {...inputProps} />;
    }

    return '';
  };

  const filterSearchTags = useCallback(
    (arrayFilter) => {
      const filterCommand = commands.filter((element) => {
        const commandCategory = element.attributes.category;

        return arrayFilter.find((item) => item.text === commandCategory);
      });
      return filterCommand;
    },
    [commands],
  );

  const filterSearch = useCallback(
    (text) => {
      const filterCommand = commandsFilter.filter((element) => {
        const command = element.attributes.name;
        return command.toLowerCase().includes(text);
      });
      if (filterCommand.length !== 0) {
        setCommandsFilter(filterCommand);
      }
      if (isEmpty(text)) setCommandsFilter(filterSearchTags(filtersTagList));
    },
    [commandsFilter, filterSearchTags, filtersTagList],
  );

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, '').toLowerCase();
    filterSearch(value);
  };

  const filtersUpdate = (array) => {
    setFiltersTagList((prevState) => {
      setCommandsFilter(filterSearchTags([...prevState, array]));
      return setFiltersTagList([...prevState, array]);
    });
  };

  const TagFilter = (item) => {
    const [active, setActive] = useState(false);

    const handleChangeFilter = (e) => {
      if (isEmpty(e.target.textContent)) return false;

      setActive(!active);
      setCommandsFilter(filterSearchTags(filters));
      const filterWithoutDuplicates = [...new Set(filtersTagList)];
      if (!active) {
        /* select all*/
        if (filterWithoutDuplicates.length === AMOUNT_OF_FILTERS - 1)
          return setFiltersTagList(filters);

        const filter = filters.filter(
          (element) => element.text === e.target.textContent,
        );
        /* select 1*/
        if (filterWithoutDuplicates.length === AMOUNT_OF_FILTERS) {
          setCommandsFilter(filterSearchTags(filter));
          return setFiltersTagList(filter);
        }
        /* select more of one*/
        return filtersUpdate(filter[0]);
      }
      /* unselect all*/
      if (filterWithoutDuplicates.length < 2) return setFiltersTagList(filters);
      /* unselect some*/
      const filter = filterWithoutDuplicates.filter(
        (element) => element.text !== e.target.textContent,
      );
      setCommandsFilter(filterSearchTags(filter));
      setFiltersTagList(filter);
      return true;
    };
    return (
      <Flex margin={0.5} key={item.color}>
        <Filter
          text={item.text}
          badgeIcon={item.icon}
          badgeColor={item.color}
          active={active}
          onClick={handleChangeFilter}
        />
      </Flex>
    );
  };

  const isLast = (type, filter) => {
    const currentCommand = selectedCommand.attributes;
    const typeArr = type.split(',');
    let selectedAttr;

    typeArr.forEach((currentType) => {
      if (selectedAttr) {
        selectedAttr = selectedAttr[currentType];
      } else {
        selectedAttr = currentCommand[currentType];
      }
    });

    return selectedAttr.indexOf(filter) === selectedAttr.length - 1;
  };

  return (
    <>
      <CommandSection
        flexDirection="column"
        justifyContent="center"
        padding={3}
        flex="none"
        flexGrow="0"
      >
        <Flex flexDirection="row">
          <Flex flex={0} alignItems="center" justifyContent="center">
            <FilterTextField
              icon={iconix.Search}
              label="searchInput"
              hideLabel
              onChange={handleChange}
            />
          </Flex>
          <Flex
            flex={3}
            alignItems="center"
            justifyContent="flex-start"
            marginLeft={1}
          >
            <p>
              <span title="FilterTags">
                <Trans>Filter by:</Trans>
              </span>
            </p>

            <Stack vertical width="100%">
              <Flex flexWrap="wrap">
                {filters.map((item) => TagFilter(item))}
              </Flex>
            </Stack>
          </Flex>
        </Flex>
      </CommandSection>
      <LineDivider />
      <Flex flexDirection="row">
        <CommandSearchSection
          flexDirection="column"
          justifyContent="flex-start"
          title="commandListSection"
        >
          <WrapperWithLoading loading={loading}>
            <CommandList
              commands={commandsFilter}
              filters={filters}
              childResult={childResult}
            />
          </WrapperWithLoading>
        </CommandSearchSection>
        <LineVerticalDivider />
        <Flex flexDirection="column" flex={3}>
          <DeveloperSectionHead justifyContent="space-between">
            <Flex flexDirection="column">
              <H1>
                {selectedCommand && titlelizeCommand(selectedCommandName)}
              </H1>
              <H4>{selectedCommand && selectedCommandDescription}</H4>
            </Flex>
            <Flex marginTop={4}>
              {tag && <Tag icon={tag.icon} text={tag.text} color={tag.color} />}
            </Flex>
          </DeveloperSectionHead>
          <Paper css="height: 100%;" padding={2}>
            <Tabs
              size="large"
              renderHiddenTabsContent={false}
              defaultSelectedId="large-tab-1"
              onChange={resetDeveloperMode}
            >
              <Tab id="large-tab-1" title={t`Command Details`}>
                <DeveloperSection
                  justifyContent="flex-start"
                  flexDirection="column"
                  flex={3}
                >
                  <Flex alignItems="center" justifyContent="flex-end">
                    <Toggle
                      name="developer-mode"
                      defaultSelected="disable"
                      options={[
                        { id: 'disable', text: 'Developer Mode' },
                        { id: 'enable', text: '' },
                      ]}
                      onChange={triggerViewModeChange}
                    />
                  </Flex>

                  {viewMode === 'default' ? (
                    <>
                      <H4 weight="semibold">
                        <Trans>Connections</Trans>
                      </H4>

                      <P color="alaskanHusky">
                        <Trans>Compatible commands</Trans>
                      </P>

                      <DisplayCode>
                        {selectedCommand &&
                          selectedCommand.attributes.connections.map(
                            (commandName) =>
                              `"${commandName}"${
                                isLast('connections', commandName) ? '' : ',\n'
                              }`,
                          )}
                      </DisplayCode>

                      <H4 weight="semibold">
                        <Trans>Properties</Trans>
                      </H4>

                      <P color="alaskanHusky">
                        <Trans>Attributes associated with the command</Trans>
                      </P>
                      {selectedCommand &&
                        selectedCommand.attributes.input_schema.properties &&
                        Object.keys(
                          selectedCommand.attributes.input_schema.properties,
                        ).map((property) => (
                          <Fragment key={property.name}>
                            <H4 weight="semibold">
                              {property
                                .split('_')
                                .map(
                                  (propertyName) =>
                                    propertyName[0].toUpperCase() +
                                    propertyName.substring(1),
                                )
                                .join(' ')}
                            </H4>
                            <DisplayCode>
                              {`"${selectedCommand.attributes.input_schema.properties[property].source}"`}
                            </DisplayCode>
                          </Fragment>
                        ))}

                      <H4 weight="semibold">
                        <Trans>Required Fields</Trans>
                      </H4>

                      <P color="alaskanHusky">
                        <Trans>Mandatory properties</Trans>
                      </P>

                      <DisplayCode>
                        {selectedCommand &&
                          selectedCommand.attributes.input_schema.properties &&
                          selectedCommand.attributes.input_schema.required.map(
                            (requiredFieldName) =>
                              `"${requiredFieldName}"${
                                isLast('schema,required', requiredFieldName)
                                  ? ''
                                  : ',\n'
                              }`,
                          )}
                      </DisplayCode>
                    </>
                  ) : (
                    selectedCommand && (
                      <>
                        <DeveloperCodeView>
                          {JSON.stringify(
                            selectedCommand.attributes.input_schema,
                            undefined,
                            2,
                          )}
                        </DeveloperCodeView>
                      </>
                    )
                  )}
                </DeveloperSection>
              </Tab>
              <Tab id="large-tab-2" title={t`Global Settings`}>
                {selectedCommand && (
                  <>
                    <Flex padding={1}>
                      <P shade={1} size="large">
                        <Trans>
                          The following Global Settings are required for the
                          integration to function. These settings are configured
                          within the integration, this page is for informational
                          purposes only.
                        </Trans>
                      </P>
                    </Flex>
                    <SectionRow>
                      <Grid.Column span="3">
                        {Object.entries(globalAttributes).length ? (
                          <H4 style={{ minWidth: '200px' }}>
                            {titlelizeCommand(selectedCommandName)}{' '}
                          </H4>
                        ) : (
                          ' '
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        {Object.entries(globalAttributes).length ? (
                          Object.entries(globalAttributes).map((input) => (
                            <FormGroup>{decideFormElement(input)}</FormGroup>
                          ))
                        ) : (
                          <Flex justifyContent="center" padding={3}>
                            <Message
                              intent="information"
                              size="large"
                              title={t`No Global Settings`}
                            >
                              <Trans>
                                This command does not require global settings.
                              </Trans>
                            </Message>
                          </Flex>
                        )}
                      </Grid.Column>
                    </SectionRow>
                  </>
                )}
              </Tab>
              <Tab id="large-tab-3" title={t`Local Settings`}>
                {selectedCommand && (
                  <>
                    <Flex padding={1}>
                      <P shade={1} size="large">
                        <Trans>
                          The following Local Settings are required for the
                          integration to function. These settings are configured
                          within the integration, this page is for informational
                          purposes only.
                        </Trans>
                      </P>
                    </Flex>
                    <SectionRow>
                      <Grid.Column span="2">
                        {Object.entries(locationAttributes).length ? (
                          <H4>{titlelizeCommand(selectedCommandName)} </H4>
                        ) : (
                          ' '
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        {Object.entries(locationAttributes).length ? (
                          Object.entries(locationAttributes).map((input) => (
                            <FormGroup>{decideFormElement(input)}</FormGroup>
                          ))
                        ) : (
                          <Flex justifyContent="center" padding={3}>
                            <Message
                              intent="information"
                              size="large"
                              title={t`No Local Settings`}
                            >
                              <Trans>
                                This command does not require location-based
                                settings.
                              </Trans>
                            </Message>
                          </Flex>
                        )}
                      </Grid.Column>
                    </SectionRow>
                  </>
                )}
              </Tab>
            </Tabs>
          </Paper>
        </Flex>
      </Flex>
    </>
  );
}
