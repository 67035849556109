import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { Code, P, Tabs } from '@decisiv/ui-components';
import spacing from '@decisiv/design-tokens/lib/spacing';
import rem from 'polished/lib/helpers/rem';
import TextField from '@decisiv/ui-components/lib/components/TextField';

export const IntegrationDetailPageHeaderBreadcrumbs = styled.div`
  padding: 0.313rem 0 0.313rem 1.25rem;
`;

export const IntegrationDetailSection = styled(Flex)`
  gap: 0.313rem;
  width: 100%;
  background: ${toColorString(color.base.snowWhite)};
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const IntegrationDetailTabs = styled(Tabs)`
  padding: 0;
  width: 100%;
  & > div:nth-child(1) {
    padding: 0 1.25rem;
    background: ${toColorString(color.base.snowWhite)};
  }
`;

export const IntegrationSettingsContainer = styled(Flex)`
  gap: 1.25rem;
`;

export const IntegrationWrapper = styled(Flex)`
  padding: 1.55rem;
  flex-direction: column;
`;

export const IntegrationsChangeHistoryContainer = styled(Flex)`
  padding: 1.5rem;
  margin-top: 1rem;
  flex-direction: column;
  background: ${toColorString(color.base.snowWhite)};
`;

export const IntegrationSettingsInfo = styled(Flex)`
  width: 283px;
  height: auto;
  padding: 1.25rem;
  gap: 0.5rem;
  background: #f1f2f8;
  align-self: start;
  align-items: start;
  flex-direction: column;
  h3 {
    word-wrap: anywhere;
  }
`;

export const ArchiveBox = styled(Flex)`
  background: ${toColorString(color.base.snowWhite)};
  margin-top: 1.25rem;
  width: 283px;
  height: auto;
  padding: 1.25rem;
  gap: 0.5rem;
  background: #f1f2f8;
  align-self: start;
  align-items: start;
  flex-direction: column;
  h3 {
    word-wrap: anywhere;
  }
`;

export const AudienceInfo = styled(Flex)`
  width: 283px;
  background: ${toColorString(color.base.snowWhite)};
  margin-top: 1.25rem;
  height: auto;
  padding: 1.25rem;
  gap: 0.5rem;
  align-self: start;
  align-items: start;
  flex-direction: column;
  h3 {
    word-wrap: anywhere;
  }
  p {
    color: #737482;
    font-size: 12px;
    margin: 0;
  }
`;

export const IntegrationSettingsForm = styled.form`
  display: flex;
  position: relative;
  width: 100%;
  padding: 1.25rem 0.8rem;
  flex-direction: column;
  background: ${toColorString(color.base.snowWhite)};
`;

export const IntegrationDetailLocations = styled(Flex)`
  height: auto;
  margin: 1.25rem;
  background-color: #fff;
`;

export const IntegrationDetailDefaultNoLocationsWrapper = styled(Flex)`
  width: 40%;
  margin: auto;
`;

export const IntegrationDetailDefaultNoLocationsTitle = styled(Flex)`
  text-align: center;
  color: ${toColorString(color.base.charcoal)};
`;

export const IntegrationDetailDefaultNoLocationsIconWrapper = styled(Flex)`
  background: ${toColorString(color.visualInterest.licoriceMousse.medium)};
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.125rem;
  padding: 0.875rem;
`;

export const CenteredContainer = styled(Flex)`
  width: 100%;
`;

export const FilterTextField = styled(TextField)`
  width: ${rem(spacing.base * 32)};
`;

export const HelpInputMessage = styled(Flex)`
  margin-top: ${rem(spacing.base * 0.2)};
  margin-bottom: ${rem(spacing.base * 2)};
  color: #737482;
`;

export const HelpInputText = styled(P)`
  margin-left: ${rem(spacing.base * 0.5)};
`;

export const NoResultDescription = styled(P)`
  width: ${rem(spacing.base * 40)};
  text-align: center;
`;

export const FlexCommand = styled(Flex)`
  width: ${rem(spacing.base * 50)};
  background-color: ${toColorString(color.base.snowWhite)};
  margin-left: ${rem(spacing.base * 2)};
  width: 810px;
  height: 800px;
`;

export const Separator = styled(Flex)`
  width: 2px;
  height: ${rem(spacing.base * 3.1)};
  margin-right: ${rem(spacing.base * 2)};
  background: ${toColorString(color.opacity.charcoal15)};
`;

export const DeveloperCodeView = styled(Code)`
  background: ${toColorString(color.base.blackSheep)};
  color: ${toColorString(color.base.snowWhite)};
  margin: 0;
  padding: 20px;
  letter-spacing: -0.8px;
  overflow: auto;
  width: 100%;
  height: 800px;
`;

export const TextAreaDeveloperCodeView = styled.textarea`
  background: ${toColorString(color.base.blackSheep)};
  color: ${toColorString(color.base.snowWhite)};
  margin: 0;
  padding: 20px;
  letter-spacing: -0.8px;
  overflow: auto;
  width: 100%;
  height: 800px;
  border-style: none;
  border-color: Transparent;
  outline: none;
  font-size: 16px;
`;
