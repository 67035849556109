import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { P, H3, Button, Flex } from '@decisiv/ui-components';
import { isEmpty, noop } from 'lodash';
import Refresh from '@decisiv/iconix/lib/components/Refresh';
import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';
import ExecutionDetailsModal from '../ExecutionDetails/ExecutionDetailsModal';
import useRetryExecutionFunctions from '../../hooks/useRetryExecutionFunctions';
import ExecutionsListTable from '../ExecutionsListTable';

export default function LocationExecutionLog({ executionsList, setRefresh }) {
  const [currentPageExecutions, setCurrentPageExecutions] = useState([]);
  const [sortedExecutions, setSortedExecutions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [totalExecutionsPage, setTotalExecutionsPage] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [groupedExecutions, setGroupedExecutions] = useState([]);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [logModalDetailsVisibility, setLogModalDetailsVisibility] =
    useState(false);
  const [selectedLogDetail, setSelectedLogDetail] = useState();
  const {
    isRetryButtonVisible,
    isRetryButtonDisabled,
    handleRetry,
    retryExecutionIdRef,
  } = useRetryExecutionFunctions();

  const groupExecutions = (items) => {
    const groupedArrays = [];

    items.forEach((item) => {
      const arn = item.attributes.step_function_arn;

      if (!groupedArrays[arn]) {
        groupedArrays[arn] = [];
      }

      groupedArrays[arn].push(item);
    });

    return Object.values(groupedArrays);
  };

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);
    let currentExecutions = [];

    if (isEmpty(sortedExecutions)) {
      currentExecutions = executions;
    } else {
      currentExecutions = sortedExecutions;
    }
    // Calculate the starting index and ending index for the specified page
    const startIndex = (newPageNumber - 1) * itemsPerPage;
    setCurrentStartIndex(startIndex);
    const endIndex = startIndex + itemsPerPage;
    setCurrentEndIndex(endIndex);
    // Use slice to get the portion of the array for the specified page
    const pageExecutions = currentExecutions?.slice(startIndex, endIndex);
    setTotalExecutionsPage(Math.ceil(currentExecutions?.length / itemsPerPage));
    setCurrentPageExecutions(pageExecutions);
  };

  useEffect(() => {
    const executionsByArn = groupExecutions(executionsList);
    setGroupedExecutions(executionsByArn);
    setExecutions(executionsList);
  }, [executionsList]);

  useEffect(() => {
    setItemsPerPage(5);
    pageChangeHandler(1);
  }, [executions]);

  useEffect(() => {
    pageChangeHandler(1);
  }, [sortedExecutions]);

  return (
    <>
      <ExecutionDetailsModal
        log={selectedLogDetail}
        visibility={logModalDetailsVisibility}
        handleVisibility={setLogModalDetailsVisibility}
        logs={executions}
        onRefreshLogs={() => setRefresh(true)}
      />
      <Flex justifyContent="space-between">
        <Flex>
          <H3 marginBottom={1.8}>
            <Trans>Executions</Trans>
          </H3>
        </Flex>
        {groupedExecutions?.length > 0 && (
          <Flex>
            <Button
              icon={Refresh}
              text={t`Refresh`}
              kind="secondary"
              size="small"
              style={{ marginRight: '0.5rem' }}
              onClick={() => {
                setRefresh(true);
              }}
            />
            <Button
              icon={ExternalLink}
              text={t`See All`}
              kind="secondary"
              size="small"
              onClick={() =>
                window.open(
                  'https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/statemachines',
                  '_blank',
                )
              }
            />
          </Flex>
        )}
      </Flex>

      {groupedExecutions?.length > 0 && (
        <>
          {groupedExecutions?.map((_group, index) => (
            <Flex flexDirection="column" marginTop={1}>
              <P color="alaskanHusky" weight="semibold">
                Arn:
              </P>
              <Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  flexWrap="nowrap"
                >
                  <P color="alaskanHusky">
                    {groupedExecutions[index][0].attributes.step_function_arn}
                  </P>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </>
      )}

      <Flex flexGrow={1} flexDirection="column">
        {groupedExecutions?.length > 0 && (
          <ExecutionsListTable
            currentPageExecutions={currentPageExecutions}
            currentStartIndex={currentStartIndex}
            currentEndIndex={currentEndIndex}
            itemsPerPage={itemsPerPage}
            executions={executions}
            totalPages={totalExecutionsPage}
            onPageChange={pageChangeHandler}
            currentPage={currentPage}
            setSelectedExecution={setSelectedLogDetail}
            isRetryButtonVisible={isRetryButtonVisible}
            isRetryButtonDisabled={isRetryButtonDisabled}
            handleRetry={handleRetry}
            retryExecutionIdRef={retryExecutionIdRef}
            setSortedExecutions={setSortedExecutions}
            getExecutions={noop}
            setExecutionModalDetailsVisibility={setLogModalDetailsVisibility}
            tableColumns={[
              'x_decisiv_trace_id',
              'http_status',
              'source',
              'trigger_type',
              'started',
              'end_time',
              'actions',
            ]}
          />
        )}
      </Flex>
    </>
  );
}

LocationExecutionLog.defaultProps = {
  executionsList: [],
  setRefresh: noop,
};

LocationExecutionLog.propTypes = {
  executionsList: PropTypes.oneOfType([PropTypes.array]),
  setRefresh: PropTypes.func,
};
